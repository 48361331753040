
  import { defineComponent } from 'vue';

  export default defineComponent({
    name: 'tabs',
    props: {
      tableUrl: { type: String, default: '' },
      areaValueUrl: { type: String, default: '' },
      tableTitle: { type: String, default: '' },
      areaValueTitle: { type: String, default: '' },
    },
    components: {},
  });
