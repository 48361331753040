import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "d-flex overflow-auto h-55px p-3" }
const _hoisted_2 = { class: "nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap" }
const _hoisted_3 = { class: "nav-item" }
const _hoisted_4 = { class: "nav-item" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("ul", _hoisted_2, [
      _createElementVNode("li", _hoisted_3, [
        _createVNode(_component_router_link, {
          to: _ctx.tableUrl,
          class: "nav-link text-active-primary me-6",
          "active-class": "active"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.tableTitle), 1)
          ]),
          _: 1
        }, 8, ["to"])
      ]),
      _createElementVNode("li", _hoisted_4, [
        _createVNode(_component_router_link, {
          class: "nav-link text-active-primary me-6",
          to: _ctx.areaValueUrl,
          "active-class": "active"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.areaValueTitle), 1)
          ]),
          _: 1
        }, 8, ["to"])
      ])
    ])
  ]))
}